<template>
  <div class="testimonials">
    <div class="statements-banner">
      <div>
        <img src="../../../static/images/placeholder/statements-jp.jpg" alt="" v-if="language === 'jp'">
        <img src="../../../static/images/placeholder/statements-en.png" alt="" v-if="language === 'en'">
        <img src="../../../static/images/placeholder/statements-lg.jpg" alt="" v-else>
      </div>
    </div>
    <div id="waterfall" class="waterfall" ref="waterfall">
      <waterfall-slot :info="content" :reflow="reflow" v-for="(content, index) in items" :key="index"></waterfall-slot>
    </div>
  </div>
</template>

<script>
import WaterfallSlot from './WaterfallSlot.vue'
import Masonry from 'masonry-layout'
import api from 'src/api/'
import { mapGetters } from 'vuex'
export default {
  name: 'testimonials',
  components: { WaterfallSlot },
  data () {
    return {
      align: 'center',
      items: [],
      listData: [],
      page: 0,
      maxNum: 1,
      fetching: false
    }
  },
  computed: {
    ...mapGetters(['language'])
  },
  created () {
    this.fetchShare().then(data => {
      this.items = data.data
      this.maxNum = data.pages
    })
  },
  mounted () {
    const grid = document.querySelector('#waterfall')
    this.masonry = new Masonry(grid, {
      itemSelector: '.waterfall-slot',
      columnWidth: 300,
      gutter: 10,
      isAnimated: true
    })

    window.addEventListener('scroll', this.scrollHandler)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.scrollHandler)
  },
  methods: {
    fetchShare () {
      this.page += 1
      if (this.page > this.maxNum) {
        return new Promise(resolve => {
          resolve([])
        })
      }
      return api.fetchTagPosts(this.page)
    },
    addItems () {
      if (this.fetching) {
        return false
      }

      this.fetching = true

      this.fetchShare().then(data => {
        if (!data.data.length) {
          this.fetching = false
          return
        }
        this.items.push.apply(this.items, data.data)
        setTimeout(() => {
          this.fetching = false
        }, 3000)
      })
    },
    reflow (el) {
      const rect = el.getBoundingClientRect()
      el.style.height = rect.height + 'px'
      this.masonry.appended(el)
    },
    scrollHandler () {
      console.log('scroll')
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop

      if (scrollTop + window.innerHeight + 400 >= document.body.clientHeight) {
        this.addItems()
      }
    }
  }
}
</script>

<style type="text/scss" lang="scss">
  .testimonials {
    .waterfall {
      position: relative;
      max-width: 1230px;
      margin: 0 auto;
      padding-top: 20px;
    }
    .fb-post {
      margin-left: 1em;
      margin-bottom: 1em;
    }
    @media only screen and (min-width : 930px) and (max-width : 1240px) {
      .waterfall {
        max-width: 920px;
      }
    }
    @media only screen and (min-width : 620px) and (max-width : 930px) {
      .waterfall {
        max-width: 610px;
      }
    }
    @media only screen and (max-width : 620px) {
      .waterfall {
        max-width: 300px;
      }
    }
  }
</style>
